import { GlobalPageContext } from './types'
import { PageDataFragment } from '../graphql-types'

const createPageContainerProps = (
  { pageTranslations, contactPage, headerNavItems, footerNavItems, secondaryNavItems }: PageDataFragment,
  pageContext: GlobalPageContext,
) => ({
  pageContext,
  pageTranslations,
  contactPath: contactPage?.path ?? '',
  headerNavItems: headerNavItems.nodes,
  footerNavItems: footerNavItems.nodes,
  secondaryNavItems: secondaryNavItems.nodes,
})

export default createPageContainerProps

import React, { FC, ComponentProps as CP } from 'react'
import { Flex } from 'rebass'

type Props = Omit<CP<typeof Flex>, 'css'>

const List: FC<Props> = ({ ...props }) => (
  <Flex
    p={0}
    as="ul"
    sx={{
      listStyleType: 'none',
    }}
    flexDirection="column"
    {...props}
  />
)

export default List

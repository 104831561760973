import React, { FC, createContext, useCallback } from 'react'
import get from 'lodash.get'
import { allowedLanguages, Language } from '../../page-config'

type TranslateFn = (key: string, values?: {[key: string]: any}) => string

type State = {
  t: TranslateFn
  lang: Language
  isDefaultLang: boolean
}

type Props = Pick<State, 'lang'> & {
  translations: any // TODO not typed
}

const [defaultLang] = allowedLanguages

const initialState: State = {
  t: () => '',
  lang: defaultLang,
  isDefaultLang: false,
}

export const IntlContext = createContext(initialState)

const IntlProvider: FC<Props> = ({ lang, children, translations = {}, ...props }) => {
  const translate: TranslateFn = useCallback((key, values) => {
    const defaultValue = '[Missing translation]'
    const base = get<string>(translations, key, defaultValue) ?? defaultValue

    if (values) {
      return Array.from(Object.entries(values)).reduce((text, [key, value]) => {
        return text.replace(`{${key}}`, value)
      }, base)
    }

    return base
  }, [translations])

  return (
    <IntlContext.Provider
      value={{
        ...props,
        lang,
        t: translate,
        isDefaultLang: lang === defaultLang,
      }}
    >
      {children}
    </IntlContext.Provider>
  )
}

export default IntlProvider

import React, { FC } from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { SeoQuery } from '../../graphql-types'
import { TranslatedPathMap, Language } from '../types'
import useIntl from '../states/useIntl'
import useSiteMetadata from '../states/useSiteMetadata'

type Props = {
  title?: string | null
  description?: string | null
  translatedPathMap: TranslatedPathMap
}

const titleProperties = ['og:title', 'twitter:title']
const descriptionProperties = ['og:description', 'twitter:description']
const imageProperties = ['og:image', 'twitter:image']

const Seo: FC<Props> = ({ title, description, translatedPathMap }) => {
  const { lang } = useIntl()
  const { title: metaTitle, description: metaDescription } = useSiteMetadata(title, description)

  const alternateLangs = Object
    .keys(translatedPathMap)
    .filter((langKey) => langKey !== lang) as Language[]

  const { shareImage }: SeoQuery = useStaticQuery(graphql`
    query Seo {
      shareImage: file(
        name: { eq: "share" }
      ) {
        childImageSharp {
          ...OpenGraphImage
        }
      }
    }
  `)

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      meta={[
        ...titleProperties.map((property) => ({ property, content: metaTitle })),
        ...descriptionProperties.map((property) => ({ property, content: metaDescription })),
        ...imageProperties.map((property) => ({
          property,
          content: [process.env.SITE_BASE_URL, shareImage!.childImageSharp!.fixed!.src].join(''),
        })),
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ]}
      link={(
        alternateLangs
          .map((langKey) => ({
            rel: 'alternate',
            hreflang: langKey,
            href: [process.env.SITE_BASE_URL, translatedPathMap[langKey]].join(''),
          }))
      )}
    >
      <link
        rel="preconnect"
        crossOrigin={undefined}
        href="https://google-analytics.com/"
      />
    </Helmet>
  )
}

export default Seo

import React, { FC, ComponentProps as CP } from 'react'
import { Flex } from 'rebass'

type Props = CP<typeof Flex>

const Grid: FC<Props> = ({ css, ...props }) => (
  <Flex
    mx={-3}
    flexWrap="wrap"
    flexDirection="row"
    {...props}
  />
)

export default Grid

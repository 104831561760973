import React, { FC, ComponentProps as CP } from 'react'
import { DefaultTheme } from 'styled-components'
import styled from '@emotion/styled'
import { Box } from 'rebass'
import Link from '../ui/Link'
import List from '../ui/List'
import ListItem from '../ui/ListItem'
import useIntl from '../states/useIntl'
import { HeaderNavItemsFragment } from '../../graphql-types'

import rightArrowUrl from '../content/images/icons/arrow_right.svg'

type Props = Omit<CP<typeof List>, 'css'> & {
  items: HeaderNavItemsFragment['headerNavItems']['nodes']
}

const NavLink = styled(Link)<{}, DefaultTheme>`
  outline: 0;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: ${props => props.theme.fontWeights.bold};
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  &:hover {
    border-bottom-color: #DDE3EC;
  }

  &.active {
    border-bottom-color: #F6941D;
  }
`

const Arrow = styled(Box)`
  width: 32px;
  height: 32px;
  background: url(${rightArrowUrl}) no-repeat center center;
  background-size: 20px;
`

const HeaderNavLinks: FC<Props> = ({ items, ...props }) => {
  const { t } = useIntl()

  return (
    <List
      mx={-3}
      {...props}
    >
      {
        items.map((item) => (
          <ListItem
            px={3}
            key={item.context?.pageId}
            sx={{
              borderColor: 'divider',
              borderBottomStyle: 'solid',
              borderBottomWidth: [1, 1, 0],
            }}
          >
            <NavLink
              tabIndex={0}
              to={item.path}
              partiallyActive
              activeClassName="active"
            >
              <Box
                py={3}
                as="span"
              >
                {t(`navigationLabels.${item.context?.pageId}`)}
              </Box>
              <Arrow display={['block', 'block', 'none']} />
            </NavLink>
          </ListItem>
        ))
      }
    </List>
  )
}

export default HeaderNavLinks

import styled from '@emotion/styled'
import { Box } from 'rebass'
import {
  zIndex,
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  position,
  top,
  right,
  bottom,
  left,
} from 'styled-system'

export default styled(Box)(
  zIndex,
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  position,
  top,
  right,
  bottom,
  left,
)

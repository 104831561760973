import React, { FC } from 'react'
import { Box } from 'rebass'
import Img, { FixedObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { PrimaryLogoQuery } from '../../graphql-types'
import useSiteMetadata from '../states/useSiteMetadata'

const PrimaryLogo: FC = () => {
  const { title } = useSiteMetadata()
  const { smallLogo, largeLogo }: PrimaryLogoQuery = useStaticQuery(graphql`
    query PrimaryLogo {
      smallLogo: file(name: { eq: "logo_primary" }) {
        childImageSharp {
          fixed(width: 106) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      largeLogo: file(name: { eq: "logo_primary" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Box display={['block', 'none']}>
        <Img
          alt={title}
          fadeIn={false}
          loading="eager"
          fixed={smallLogo!.childImageSharp!.fixed as FixedObject}
        />
      </Box>
      <Box display={['none', 'block']}>
        <Img
          alt={title}
          fadeIn={false}
          loading="eager"
          fixed={largeLogo!.childImageSharp!.fixed as FixedObject}
        />
      </Box>
    </>
  )
}

export default PrimaryLogo

import React, { FC, useState, useCallback, useEffect } from 'react'
import parseHtml, { domToReact } from 'html-react-parser'
import { DefaultTheme } from 'styled-components'
import galite from 'ga-lite'
import styled from '@emotion/styled'
import Cookies from 'universal-cookie'
import addYears from 'date-fns/addYears'
import { Flex, Box, Text } from 'rebass'
import { CompactCTAButton } from './CTAButton'
import Link from '../ui/Link'
import Grid from '../ui/Grid'
import GridItem from '../ui/GridItem'
import ContainerFixed from '../ui/ContainerFixed'
import useIntl from '../states/useIntl'
import internetIconUrl from '../content/images/icons/internet.svg'

type Props = {
  cookieName?: string
}

const cookies = new Cookies()

const StyledContainer = styled(ContainerFixed)<{}, DefaultTheme>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .9;
  border-radius: 10px;
  border: 1px solid #DDE3EC;
  z-index: ${props => props.theme.zIndices![2]};
`

const StyledLink = styled(Link)<{}, DefaultTheme>`
  color: ${props => props.theme.colors.primary}
`

const CookieConsent: FC<Props> = ({ cookieName = 'ormo-gdpr-google-analytics' }) => {
  const { t } = useIntl()
  const [isDisplayed, setIsDisplayed] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (cookies.get(cookieName) === undefined) {
        setIsDisplayed(true)
      }
    }, 1000)
  }, [cookieName])

  const accept = useCallback(() => {
    setIsDisplayed(false)

    cookies.set(cookieName, true, {
      expires: addYears(new Date(), 1),
    })

    galite('send', 'pageview')
  }, [cookieName])

  if (!isDisplayed) {
    return null
  }

  return (
    <StyledContainer
      mb={4}
      as="aside"
      color="text"
      bg="background"
      fontSize={14}
      maxWidth={['90%', '90%', 864]}
    >
      <Grid
        alignItems="center"
      >
        <GridItem py="21px">
          <Box
            as="img"
            alt=""
            src={internetIconUrl}
          />
        </GridItem>

        <GridItem
          flex={1}
          py="21px"
        >
          <Text>
            {
              parseHtml(t('cookieConsent.message'), {
                replace: (domNode: any) => {
                  if (domNode.name === 'a') {
                    return (
                      <StyledLink
                        to={domNode?.attribs?.href}
                      >
                        {domToReact(domNode.children)}
                      </StyledLink>
                    )
                  }
                },
              })
            }
          </Text>
        </GridItem>

        <GridItem
          py="21px"
          width={[1, 1, 'auto']}
        >
          <Flex justifyContent="center">
            <CompactCTAButton
              onClick={accept}
            >
              {t('cookieConsent.acceptCta')}
            </CompactCTAButton>
          </Flex>
        </GridItem>
      </Grid>
    </StyledContainer>
  )
}

export default CookieConsent

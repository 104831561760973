import React, { FC, ComponentProps as CP } from 'react'
import Box from './Box'

type Props = CP<typeof Box>

const GridItem: FC<Props> = ({ css, ...props }) => (
  <Box
    px={3}
    {...props}
  />
)

export default GridItem

import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Box, Button } from 'rebass'
import { DefaultTheme } from 'styled-components'
import { TranslatedPathMap } from '../types'
import { allowedLanguages } from '../../page-config'
import useIntl from '../states/useIntl'
import Link from '../ui/Link'
import List from '../ui/List'
import ListItem from '../ui/ListItem'

import upChevronUrl from '../content/images/icons/chevron_up.svg'
import bottomChevronUrl from '../content/images/icons/chevron_bottom.svg'

type Props = {
  translatedPathMap: TranslatedPathMap
}

const titles = {
  en: 'EN',
  hu: 'HU',
}

const Dropdown = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 20px;
`

const DropdownTrigger = styled(Button)`
  outline: 0;
  z-index: 3;
  height: auto;
  color: inherit;
  cursor: pointer;
  text-align: left;
  position: relative;
  padding-right: 18px;
  font-weight: inherit;
`

const DropdownContainer = styled(Box)<{}, DefaultTheme>`
  line-height: 1.2;
  position: relative;
  font-weight: ${props => props.theme.fontWeights.bold};

  & ${Dropdown} {
    display: none;
  }

  &:focus-within ${Dropdown} {
    display: block;
  }

  & ${DropdownTrigger} {
    background: url(${bottomChevronUrl}) no-repeat 80% center;
    background-size: 11px;
  }

  &:focus-within ${DropdownTrigger} {
    pointer-events: none;
    background: url(${upChevronUrl}) no-repeat 80% center;
  }
`

const StyledLink = styled(Link)`
  outline: 0;
  width: 100%;
  color: inherit;
  display: block;
  text-decoration: none;
`

const LanguagePicker: FC<Props> = ({ translatedPathMap }) => {
  const { lang: currentLang } = useIntl()

  const alternateLanguages = allowedLanguages.filter((allowedLang) => (
    allowedLang !== currentLang
  ))

  return (
    <DropdownContainer
      width={70}
    >
      <DropdownTrigger
        px={3}
        width={1}
        as="div"
        role="button"
        tabIndex={0}
      >
        {titles[currentLang]}
      </DropdownTrigger>
      <Dropdown
        pt="50%"
        pb={2}
        as="nav"
        width={1}
        bg="white"
      >
        <List
          width={1}
        >
          {
            alternateLanguages
              .map((langKey) => {
                const translatedPath = translatedPathMap[langKey]

                return (
                  <ListItem
                    width={1}
                    key={langKey}
                  >
                    <StyledLink
                      replace
                      tabIndex={0}
                      role="button"
                      to={translatedPath}
                    >
                      <Box px={3} >
                        {titles[langKey]}
                      </Box>
                    </StyledLink>
                  </ListItem>
                )
              })
          }
        </List>
      </Dropdown>
    </DropdownContainer>
  )
}

export default LanguagePicker

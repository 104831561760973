import React, { FC, ComponentProps as CP } from 'react'
import Link from '../ui/Link'
import useIntl from '../states/useIntl'

type Props = Omit<CP<typeof Link>, 'to'>

// assumes default home url is always "/" and localized home url is "/${lang}"
const HomeLink: FC<Props> = ({ ref, ...props }) => {
  const { lang, isDefaultLang } = useIntl()

  return (
    <Link
      {...props}
      to={`/${isDefaultLang ? '' : lang}`}
    />
  )
}

export default HomeLink

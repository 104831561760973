const path = require('path')

const defaultLanguage = 'en'

exports.allowedLanguages = [defaultLanguage]
exports.pages = [
  {
    id: 'home',
    locations: {
      header: false,
      footer: false,
    },
    path: {
      en: '/',
      // hu: '/hu',
    },
    component: path.resolve('./src/templates/home.tsx'),
  },
  {
    id: 'work',
    locations: {
      header: true,
      footer: true,
    },
    path: {
      en: '/work',
      // hu: '/munkaink',
    },
    component: path.resolve('./src/templates/work.tsx'),
  },
  {
    id: 'services',
    locations: {
      header: true,
      footer: true,
    },
    path: {
      en: '/services',
      // hu: '/szolgaltatasok',
    },
    component: path.resolve('./src/templates/services.tsx'),
  },
  {
    id: 'about',
    locations: {
      header: true,
      footer: true,
    },
    path: {
      en: '/about',
      // hu: '/rolunk',
    },
    component: path.resolve('./src/templates/about.tsx'),
  },
  {
    id: 'contact',
    locations: {
      header: false,
      footer: true,
    },
    path: {
      en: '/contact',
      // hu: '/kapcsolat',
    },
    component: path.resolve('./src/templates/contact.tsx'),
  },
  {
    id: 'privacy',
    path: {
      en: '/privacy-policy',
      // hu: '/adatvedelmi-iranyelvek',
    },
    component: path.resolve('./src/templates/privacy-policy.tsx'),
  },
  {
    id: 'legal',
    path: {
      en: '/legal',
      // hu: '/jogi-nyilatkozat',
    },
    component: path.resolve('./src/templates/legal.tsx'),
  },
]

import styled from '@emotion/styled'
import { Button } from 'rebass'
import {
  zIndex,
  position,
} from 'styled-system'

export default styled(Button)(
  zIndex,
  position,
)

import React, { FC, ComponentProps as CP } from 'react'
import styled from '@emotion/styled'
import { DefaultTheme } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { Link as RebassLink } from 'rebass'

type Props = Omit<CP<typeof RebassLink>, 'href' | 'css'> & CP<typeof GatsbyLink>

const Link: FC<Props> = (props) => (
  <RebassLink
    as={() => (
      <GatsbyLink
        {...props}
      />
    )}
  />
)

export default styled(Link)<Props, DefaultTheme>({}, (props) => ({
  ...props.theme.link,
}))

import React, { FC, ComponentProps as CP } from 'react'
import Box from './Box'

type Props = CP<typeof Box>

const ContainerFluid: FC<Props> = ({ css, ...props }) => {
  return (
    <Box
      px={3}
      mx="auto"
      {...props}
    />
  )
}

export default ContainerFluid

import { useStaticQuery, graphql } from 'gatsby'
import { SiteMetadataQuery } from '../../graphql-types'

type SiteMetaData = {
  title: string
  description: string
}

export default function useSiteMetadata(title?: string | null, description?: string | null) {
  const { site }: SiteMetadataQuery = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
          }
        }
      }
    `,
  )

  return {
    title: title || site?.siteMetadata?.defaultTitle,
    description: description || site?.siteMetadata?.defaultDescription,
  } as SiteMetaData
}

import React, { FC, ComponentProps as CP } from 'react'

import Seo from './Seo'
import Header from './Header'
import Footer from './Footer'
import CookieConsent from './CookieConsent'
import IntlProvider from '../states/IntlContext'
import { GlobalPageContext, TranslatedPathMap } from '../types'
import {
  TranslatedPagesFragment,
  HeaderNavItemsFragment,
  FooterNavItemsFragment,
  SecondaryNavItemsFragment,
} from '../../graphql-types'

type Props = Pick<CP<typeof IntlProvider>, 'translations'> &
  Pick<TranslatedPagesFragment, 'pageTranslations'> & {
    pageContext: GlobalPageContext
    contactPath: string
    headerNavItems: HeaderNavItemsFragment['headerNavItems']['nodes']
    footerNavItems: FooterNavItemsFragment['footerNavItems']['nodes']
    secondaryNavItems: SecondaryNavItemsFragment['secondaryNavItems']['nodes']
  }

const toTranslatedPathMap = (pageTranslations: Props['pageTranslations']) => (
  pageTranslations.nodes.reduce((map, item) => ({
    ...map,
    [item.context!.lang!]: item.path,
  }), {} as TranslatedPathMap)
)

const PageContainer: FC<Props> = ({
  pageContext, translations, pageTranslations, contactPath, headerNavItems, footerNavItems, secondaryNavItems, children,
}) => {
  const pathMap = toTranslatedPathMap(pageTranslations)

  return (
    <IntlProvider
      lang={pageContext.lang}
      translations={translations}
    >
      <Seo
        {...translations!.meta}
        translatedPathMap={pathMap}
      />
      <CookieConsent />
      <Header
        contactPath={contactPath}
        navItems={headerNavItems}
        translatedPathMap={pathMap}
      />
      {children}
      <Footer
        navItems={footerNavItems}
        secondaryNavItems={secondaryNavItems}
      />
    </IntlProvider>
  )
}

export default PageContainer

import React, { FC } from 'react'
import Img, { FixedObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { SecondaryLogoQuery } from '../../graphql-types'
import useSiteMetadata from '../states/useSiteMetadata'

const SecondaryLogo: FC = () => {
  const { title } = useSiteMetadata()
  const { logoImage }: SecondaryLogoQuery = useStaticQuery(graphql`
    query SecondaryLogo {
      logoImage: file(name: { eq: "logo_secondary" }) {
        childImageSharp {
          fixed(width: 158) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      alt={title}
      fadeIn={false}
      loading="eager"
      fixed={logoImage!.childImageSharp!.fixed as FixedObject}
    />
  )
}

export default SecondaryLogo

import styled from '@emotion/styled'
import { Box, Text, Flex } from 'rebass'
import React, { FC, ComponentProps as CP } from 'react'

import Link from '../ui/Link'
import List from '../ui/List'
import ListItem from '../ui/ListItem'
import useIntl from '../states/useIntl'
// import FacebookLogo from './FacebookLogo'
// import LinkedInLogo from './LinkedInLogo'
// import InstagramLogo from './InstagramLogo'
import SecondaryLogo from './SecondaryLogo'
// import LinkExternal from '../ui/LinkExternal'
import ContainerFixed from '../ui/ContainerFixed'
import { FooterNavItemsFragment, SecondaryNavItemsFragment } from '../../graphql-types'

const FooterLink = styled(Link)`
  color: inherit;
  font-size: inherit;
  text-decoration: none;

  &.active {
    font-weight: bold;
  }
`

const Copyright = () => {
  const { t } = useIntl()
  const year = new Date().getFullYear()

  return (
    <Text
      as="span"
      variant="neutral.primary"
      fontSize={[ 12, 12, 16 ]}
    >
      {t('footer.copyright', {year})}
    </Text>
  )
}

type Props = {
  navItems: FooterNavItemsFragment['footerNavItems']['nodes']
  secondaryNavItems: SecondaryNavItemsFragment['secondaryNavItems']['nodes']
}

type LinksProps = Omit<CP<typeof List>, 'css'> & {
  items: Props['navItems']
}

const Links: FC<LinksProps> = ({ items, ...props }) => {
  const { t } = useIntl()

  return (
    <List {...props}>
      {
        items.map((item) => (
          <ListItem
            px={[0, 0, 3]}
            // pt={[3, 3, 0]}   NOTE: uncomment this when putting social links back on
            py={[ 2, 2, 0 ]} // NOTE: remove this when putting social links back on
            key={item.context?.pageId}
          >
            <FooterLink to={item.path} >
              {t(`navigationLabels.${item.context?.pageId}`)}
            </FooterLink>
          </ListItem>
        ))
      }
    </List>
  )
}

const Footer: FC<Props> = ({ navItems, secondaryNavItems }) => {
  return (
    <Box as="footer">
      <Box bg="secondary">
        <ContainerFixed py={4}>
          <Flex
            alignItems="center"
            flexDirection={[ 'column-reverse', 'column-reverse', 'row' ]}
          >
            <Box display={[ 'none', 'none', 'block' ]}>
              <SecondaryLogo />
            </Box>

            <Box
              flex={1}
              display={[ 'none', 'none', 'block' ]}
            >
              <Links
                ml={3}
                color="white"
                fontSize={16}
                flexDirection="row"
                items={navItems}
              />
            </Box>

            <Box display={[ 'block', 'block', 'none' ]}>
              <Links
                color="white"
                fontSize={12}
                alignItems="center"
                flexDirection="column"
                items={secondaryNavItems}
              />
            </Box>

            {/*
            <Flex pb={[2, 2, 0]}>
              <Box>
                <LinkExternal href="https://facebook.com">
                  <FacebookLogo />
                </LinkExternal>
              </Box>
              <Box px={4}>
                <LinkExternal href="https://linkedin.com">
                  <LinkedInLogo />
                </LinkExternal>
              </Box>
              <Box>
                <LinkExternal href="https://instagram.com">
                  <InstagramLogo />
                </LinkExternal>
              </Box>
            </Flex>
            */}
          </Flex>
        </ContainerFixed>
      </Box>
      <Box bg="neutral">
        <ContainerFixed py={3}>
          <Flex
            alignItems="center"
            flexDirection={[ 'column', 'column', 'row' ]}
          >
            <Box flex={1}>
              <Copyright />
            </Box>

            <Box display={[ 'none', 'none', 'flex' ]}>
              <Links
                mx={-3}
                color="white"
                fontSize={16}
                flexDirection="row"
                items={secondaryNavItems}
              />
            </Box>
          </Flex>
        </ContainerFixed>
      </Box>
    </Box>
  )
}

export default Footer

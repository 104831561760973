import React, { FC, ComponentProps as CP } from 'react'
import { Button } from 'rebass'
import { Link } from 'gatsby'

type Props = CP<typeof Button> & CP<typeof Link>

const LinkButton: FC<Props> = (props: any) => (
  <Button
    as={Link}
    {...props}
  />
)

export default LinkButton

import React, { FC, ComponentProps as CP } from 'react'
import { DefaultTheme } from 'styled-components'
import styled from '@emotion/styled'
import HomeLink from './HomeLink'
import PrimaryLogo from './PrimaryLogo'
import NavLinks from './HeaderNavLinks'
import LinkButton from '../ui/LinkButton'
import Box from '../ui/Box'
import Button from '../ui/Button'
import Grid from '../ui/Grid'
import GridItem from '../ui/GridItem'
import ContainerFluid from '../ui/ContainerFluid'
import LanguagePicker from './LanguagePicker'
import useIntl from '../states/useIntl'
import { allowedLanguages } from '../../page-config'
import { HeaderNavItemsFragment } from '../../graphql-types'

import openMenuUrl from '../content/images/icons/menu_open.svg'
import closeMenuUrl from '../content/images/icons/menu_close.svg'

type Props = CP<typeof LanguagePicker> & {
  contactPath: string
  navItems: HeaderNavItemsFragment['headerNavItems']['nodes']
}

const StickyContainer = styled(ContainerFluid)<{}, DefaultTheme>`
  top: 0;
  position: sticky;
`

const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background: rgba(240, 244, 249, .75);
`

const PositionedHomeLink = styled(HomeLink)<{}, DefaultTheme>`
  outline: 0;
  position: relative;
`

const MenuContainer = styled(ContainerFluid)``

const MenuButton = styled(Button)<{}, DefaultTheme>`
  width: 32px;
  height: 32px;
`

const Checkbox = styled('input')`
  display: none;

  & ~ ${MenuContainer} {
    display: none;
  }

  &:checked ~ ${MenuContainer} {
    display: block;
    user-select: none;
  }

  & ~ ${MenuButton} {
    background: url(${openMenuUrl}) no-repeat center center;
    background-size: 26px;
  }

  &:checked ~ ${MenuButton} {
    background: url(${closeMenuUrl}) no-repeat center center;
    background-size: 24px;
  }
`

const Header: FC<Props> = ({ translatedPathMap, contactPath, navItems }) => {
  const { t } = useIntl()

  return (
    <StickyContainer
      as="header"
      zIndex={1}
      borderBottom={[0, 0, '1px solid #DDE3EC']}
    >
      <Overlay />
      <Grid
        minHeight={90}
        alignItems="center"
        justifyContent="space-between"
      >
        <GridItem zIndex={2}>
          <PositionedHomeLink
            replace
            tabIndex={0}
          >
            <PrimaryLogo />
          </PositionedHomeLink>
        </GridItem>
        <GridItem>
          <Checkbox
            type="checkbox"
            id="menuToggle"
          />
          <MenuButton
            as="label"
            zIndex={2}
            position="relative"
            htmlFor="menuToggle"
            title={t('toggleMenu')}
            display={['block', 'block', 'none']}
          />
          <MenuContainer
            px={0}
            py={[6, 6, 0]}
            position={['fixed', 'fixed', 'relative']}
            top={0}
            right={0}
            bottom={0}
            left={0}
            display={['initial', 'initial', 'block !important']}
          >
            <Overlay display={['block', 'block', 'none']} />
            <Box
              px={[4, 4, 0]}
              position="relative"
            >
              <Grid alignItems="center">
                <GridItem
                  mb={[3, 3, 0]}
                  width={[1, 1, 'auto']}
                >
                  <NavLinks
                    items={navItems}
                    flexDirection={['column', 'column', 'row']}
                  />
                </GridItem>
                {
                  allowedLanguages.length > 1 && (
                    <GridItem
                      px={0}
                      width={[1, 1, 'auto']}
                    >
                      <LanguagePicker
                        translatedPathMap={translatedPathMap}
                      />
                    </GridItem>
                  )
                }
                <GridItem
                  my={[3, 3, 0]}
                  width={[1, 1, 'auto']}
                >
                  <LinkButton
                    to={contactPath}
                    variant="primary"
                  >
                    {t('contactUs')}
                  </LinkButton>
                </GridItem>
              </Grid>
            </Box>
          </MenuContainer>
        </GridItem>
      </Grid>
    </StickyContainer>
  )
}

export default Header

import React, { FC, ComponentProps as CP, HTMLProps } from 'react'
import styled from '@emotion/styled'
import { DefaultTheme } from 'styled-components'
import { Box, Button } from 'rebass'

import rightArrowUrl from '../content/images/icons/arrow_right.svg'

const CTAButton = styled(Button)<CP<typeof Button>, DefaultTheme>`
  display: inline-flex;
  align-items: center;
  height: 60px;
  outline: none;
  padding-left: 28px;
  padding-right: ${props => 28 + (props.size === 'large' ? 35 : 20)}px;
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes![props.size === 'large' ? 4 : 0]}px;
  font-weight: ${props => props.theme.fontWeights.bold};
  background-size: ${props => props.size === 'large' ? 30 : 20}px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: right 20px center;
  background-image: url(${rightArrowUrl});

  &:hover {
    cursor: pointer;
  }
`

const Container = styled(Box)`
  position: relative;
  display: inline-block;
`

const Background = styled.div<HTMLProps<HTMLDivElement>, DefaultTheme>`
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  right: auto;
  position: absolute;
  border-radius: 30px;
  background-color: ${props => props.theme.colors.primary};
  transition: width 0.5s;
  padding-right: 15px;

  ${Container}:hover & {
    width: 100%;
  }
`

type Props = Omit<CP<typeof Button>, 'css'> & {
  containerProps?: Omit<CP<typeof Box>, 'css'>
}

export const CompactCTAButton: FC<Props> = ({ containerProps, sx, ...props }) => {
  return (
    <Container {...containerProps}>
      <Background />

      <CTAButton
        sx={{ ...sx, position: 'absolute' }}
        {...props}
      />

      <CTAButton
        sx={{ ...sx, visibility: 'hidden' }}
        {...props}
      />
    </Container>
  )
}

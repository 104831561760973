import React, { FC, ComponentProps as CP } from 'react'
import Box from './Box'

type Props = CP<typeof Box>

const ListItem: FC<Props> = ({ css, ...props }) => (
  <Box
    p={0}
    as="li"
    {...props}
  />
)

export default ListItem
